import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Space } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useQuery } from 'react-query';
import {
  getSensorDeviceBarcodeOptions,
  getSensorTypeOptions,
} from '@root/services/sdeInventories/sde.edge-application.service.js';
import CustomSelect from '@root/components/atoms/CustomSelect.jsx';
import { controllerTypeOptions } from '@root/utils/controllerTypeOptions.js';
import { locationOptions } from '@root/utils/locationOptions.js';

export default function SensorFormList({ applicationName, fieldKey, form, isAddButtonDisabled, record }) {
  const [sensorOptions, setSensorOptions] = useState(null);

  const { data: deviceBarcodeOptions, refetch: reFetchSensorBarcodeOptions } = useQuery(
    'get_sensor_device_barcode_options',
    getSensorDeviceBarcodeOptions,
    {
      onSuccess: (data) => {
        const sensorBarcodes = form
          .getFieldValue(['applications'])
          .flatMap((entry) => entry.sensors.map((sensor) => sensor?.deviceBarcode));
        const allSensorInEdge = record.applications.flatMap((entry) =>
          entry.sensors.map((sensor) => {
            return {
              id: sensor?.id,
              deviceBarcode: sensor?.deviceBarcode,
            };
          }),
        );

        let filteredSensorOptions = [];

        const currentApplicationSensor = form
          .getFieldValue(['applications', applicationName, 'sensors'])
          .map((sensor) => sensor?.deviceBarcode);

        for (const currentSelectedSensor of currentApplicationSensor) {
          if (currentSelectedSensor) {
            const targetSensorData = data.data.filter((sensor) => sensor.value === currentSelectedSensor);
            if (
              targetSensorData &&
              targetSensorData.length > 0 &&
              !filteredSensorOptions.some((item) => item.value === targetSensorData[0].value)
            ) {
              filteredSensorOptions.push(targetSensorData[0]);
            }
          }
        }

        for (const sensorInEdge of allSensorInEdge) {
          if (sensorInEdge && !sensorBarcodes.includes(sensorInEdge.deviceBarcode)) {
            filteredSensorOptions.push({
              value: sensorInEdge.id,
              label: sensorInEdge.deviceBarcode,
            });
          }
        }

        for (const currentSelectedSensor of form.getFieldValue(['applications', applicationName, 'sensors'])) {
          if (currentSelectedSensor) {
            if (
              !filteredSensorOptions
                .map((filterOption) => {
                  return filterOption.value;
                })
                .includes(currentSelectedSensor.deviceBarcode)
            ) {
              filteredSensorOptions.push({
                value: currentSelectedSensor.id,
                label: currentSelectedSensor.deviceBarcode,
              });
            }
          }
        }
        filteredSensorOptions.push(...data.data.filter((item) => !sensorBarcodes.includes(item.value)));

        filteredSensorOptions = filteredSensorOptions.filter(
          (obj, index, self) => index === self.findIndex((o) => o.value === obj.value),
        );

        setSensorOptions(filteredSensorOptions);
      },
      enabled: false,
    },
  );

  const { data: sensorTypeOptions, refetch: reFetchSensorTypeOptions } = useQuery(
    ['get_sensor_type_options', form.getFieldValue(['applications', applicationName])?.applicationType],
    () => getSensorTypeOptions(form.getFieldValue(['applications', applicationName])?.applicationType),
    {
      onSuccess: (data) => {
        // const sensorData = form.getFieldValue(['applications']).flatMap((entry) =>
        //   entry.sensors.map((sensor) => {
        //     return {
        //       deviceBarcode: sensor?.deviceBarcode,
        //       deviceType: sensor?.deviceType,
        //     };
        //   }),
        // );
      },
    },
  );

  const handleDeviceBarcodeDropdownOpen = (open) => {
    if (open) {
      reFetchSensorBarcodeOptions();
    }
  };

  const handleDeviceTypeDropdownOpen = (open) => {
    if (open) {
      reFetchSensorTypeOptions();
    }
  };

  useEffect(() => {
    reFetchSensorBarcodeOptions();
  }, []);

  return (
    <div className="col-span-4 pl-7">
      <div className="pb-[8px]">Sensors</div>
      <Form.List name={[applicationName, 'sensors']} initialValue={[]}>
        {(deviceFields, { add: addDevice, remove: removeDevice }) => (
          <>
            {deviceFields.map(({ key: sensorKey, name: sensorName, fieldKey: sensorFieldKey }, index) => (
              <Space key={sensorKey} className="flex gap-2 items-baseline" align="base-line">
                <Form.Item
                  {...sensorName}
                  name={[sensorName, 'deviceBarcode']}
                  fieldKey={[sensorFieldKey, 'deviceBarcode']}
                  rules={[
                    {
                      required: true,
                      message: 'Device barcode is required',
                    },
                  ]}
                  className="w-[200px]"
                >
                  <CustomSelect
                    options={sensorOptions}
                    placeholder="Select device barcode"
                    onDropdownVisibleChange={handleDeviceBarcodeDropdownOpen}
                  />
                </Form.Item>
                <Form.Item
                  {...sensorName}
                  name={[sensorName, 'deviceType']}
                  fieldKey={[sensorFieldKey, 'deviceType']}
                  rules={[
                    {
                      required: true,
                      message: 'Device type is required',
                    },
                  ]}
                  className="w-[200px]"
                >
                  <CustomSelect
                    options={sensorTypeOptions?.data}
                    placeholder="Select device type"
                    onDropdownVisibleChange={handleDeviceTypeDropdownOpen}
                  />
                </Form.Item>
                <Form.Item
                  {...sensorName}
                  name={[sensorName, 'controllerType']}
                  fieldKey={[sensorFieldKey, 'controllerType']}
                  rules={[
                    {
                      required: true,
                      message: 'Controller type is required',
                    },
                  ]}
                  className="w-[200px]"
                >
                  <CustomSelect options={controllerTypeOptions} placeholder="Select controller type" />
                </Form.Item>
                <Form.Item
                  {...sensorName}
                  name={[sensorName, 'location']}
                  fieldKey={[sensorFieldKey, 'location']}
                  rules={[
                    {
                      required: true,
                      message: 'location is required',
                    },
                  ]}
                  className="w-[200px]"
                >
                  <CustomSelect options={locationOptions} placeholder="Select location" />
                </Form.Item>
                <Form.Item
                  {...sensorName}
                  name={[sensorName, 'roomName']}
                  fieldKey={[sensorFieldKey, 'roomName']}
                  rules={[
                    {
                      required: true,
                      message: 'Room name is required',
                    },
                  ]}
                >
                  <Input placeholder="Room name" />
                </Form.Item>

                <div className="flex gap-2">
                  <MinusCircleOutlined onClick={() => removeDevice(sensorName)} />
                </div>
              </Space>
            ))}
            <Form.Item>
              <Button
                disabled={isAddButtonDisabled}
                type="dashed"
                onClick={() => {
                  addDevice();
                }}
                icon={<PlusOutlined />}
              >
                Add sensor
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </div>
  );
}
