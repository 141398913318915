import {
  AppstoreOutlined,
  DashboardOutlined,
  DeleteOutlined,
  DownOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SwapOutlined,
  TableOutlined,
  TagsOutlined,
  TeamOutlined,
  UsergroupAddOutlined,
  UserOutlined,
  WifiOutlined,
} from '@ant-design/icons';
import { hasReadAccess } from '@root/utils/permission';
import { useAuth } from '@store/auth/hook';
import { GlobalHook } from '@store/global/globalHook';
import { Avatar, Button, Dropdown, Layout, Menu, Space } from 'antd';
import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { debounce } from 'lodash';
import AdminRoute from './router';
import Style from './style';
import CallCenterPortalLogo from './components/callCenterPortalLogo';
import SDEInventoriesIcon from './components/SDEInventoriesIcon';


const { Header, Content, Sider } = Layout;

const UserTemplate = () => {
  const { user, logout } = useAuth();

  const [collapsed, setCollapsed] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState(window.location.pathname.replace('/dashboard', ''));

  useEffect(() => {
    if (user) {
      onMouseMove();
      window.addEventListener('mousemove', onMouseMove);
    }
    return () => {
      window.removeEventListener('mousemove', onMouseMove);
    };
  }, [user]);

  const onMouseMove = debounce(() => {
    set30min();
  }, 1000);

  let timeLogout = null;
  const set30min = useCallback(() => {
    if (timeLogout) {
      clearTimeout(timeLogout);
    }
    timeLogout = setTimeout(() => {
      logout();
    }, 30 * 60 * 1000);
  }, []);

  const userMenu = (
    <Menu>
      <Menu.Item onClick={() => logout()} icon={<LogoutOutlined />}>
        Log out
      </Menu.Item>
    </Menu>
  );

  const menuItems = useMemo(() => {
    return [
      {
        path: '',
        scope: 'dashboard',
        title: 'Dashboard',
        icon: <DashboardOutlined />,
      },
      {
        path: '/edge-applications',
        scope: 'edge-applications',
        title: 'Edge Applications',
        icon: <AppstoreOutlined />,
      },
      {
        path: '/edge-wifi',
        scope: 'edge-wifi',
        title: 'Edge Wifi',
        icon: <WifiOutlined />,
      },
      {
        path: '/edge-associate-devices',
        scope: 'edge-associate-devices',
        title: 'Associate Devices',
        icon: <AppstoreOutlined />,
      },
      {
        path: '/customers',
        scope: 'customers',
        title: 'Customers',
        icon: <UsergroupAddOutlined />,
      },
      {
        path: '/users',
        scope: 'users',
        title: 'Users',
        icon: <UserOutlined />,
      },
      {
        path: '/user-groups',
        scope: 'user-groups',
        title: 'User Groups',
        icon: <TeamOutlined />,
      },
      {
        path: '/transfer-edge',
        scope: 'edge-data',
        title: 'Transfer Edge Data',
        icon: <SwapOutlined />,
      },
      {
        path: '/delete-edge',
        scope: 'edge-data/delete',
        title: 'Delete Edge Data',
        icon: <DeleteOutlined />,
      },
      {
        path: '/scg-id',
        scope: 'scg-id',
        title: 'Manage SCG ID',
        icon: <TagsOutlined />,
      },
      {
        path: '/log-management',
        scope: 'log-management',
        title: 'Log management',
        icon: <TableOutlined />,
      },
      {
        path: '/call-center-portal',
        scope: 'call-center-portal/read',
        title: 'Call Center Portal',
        icon: <CallCenterPortalLogo />,
      },
      {
        path: '/sde-inventories',
        scope: 'sde-inventories',
        title: 'SDE Inventories',
        icon: <SDEInventoriesIcon/>,
      },
    ].filter((item) => hasReadAccess(user, item.scope));
  }, [user.userRoleKeys]);

  return (
    <Style>
      <Layout style={{ minHeight: '100vh' }}>
        <Sider trigger={null} collapsible collapsed={collapsed}>
          <div className="logo" />
          <Menu theme="dark" mode="inline" selectedKeys={[selectedKeys]}>
            {menuItems.map((item, index) => (
              <Menu.Item key={item.path || index} icon={item.icon} onClick={(value) => setSelectedKeys(value.key)}>
                <Link to={`/dashboard${item.path}`}>{item.title}</Link>
              </Menu.Item>
            ))}
          </Menu>
        </Sider>
        <Layout className="site-layout">
          <Header className="site-layout-background custom-header" style={{ padding: 0 }}>
            {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
              className: 'trigger',
              onClick: () => setCollapsed((prev) => !prev),
            })}

            <span className="trigger">
              <Dropdown overlay={userMenu}>
                <Button type="link">
                  <Space size={12}>
                    <Avatar icon={<UserOutlined />} />
                    <span>{user.firstName}</span>
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
            </span>
          </Header>
          <Content className="site-layout-background layout-content">
            <GlobalHook>
              <AdminRoute />
            </GlobalHook>
          </Content>
        </Layout>
      </Layout>
    </Style>
  );
};

export default UserTemplate;
