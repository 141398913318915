import { editSDEEdgeApplication } from '@root/services/sdeInventories/sde.edge-application.service.js';
import { Form, Steps, Modal, Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import Step1FormItems from './Step1FormItems.jsx';
import Step2FormItems from './Step2FormItems.jsx';
const { Step } = Steps;

export default function AddEdgeApplication({ record, handleSave, handleSaveSuccess, handleSaveError }) {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const [step1Data, setStep1Data] = useState({});
  const [current, setCurrent] = useState(0);
  const [solutionStates, setSolutionStates] = useState([]);
  const [formData, setformData] = useState({});
  const [formErrorFields, setformErrorFields] = useState([]);
  const [showAlertModal, setshowAlertModal] = useState(false);

  const requiredFieldLabels = {
    // businessUnit: 'Business Unit',
    // installedDate: 'Installed date',
    // developer: 'Developer',
    // project: 'Project',
    // monitoringStatus: 'MonitoringStatus',
    // virtual: 'Virtual',
    reference: 'Reference',
    externalIdentity: 'External Identity',
    integrationCode: 'Integration Code',
  };

  const onValuesChange = () => {
    const applications = form.getFieldValue('applications') || [];
    const states = applications.map((solution) => !!solution?.applicationType);
    setSolutionStates(states);
  };

  const { mutate: updateEdgeApplication, isLoading } = useMutation(editSDEEdgeApplication, {
    onSuccess: () => {
      queryClient.invalidateQueries(['get_sde_edge_application']);
      handleSaveSuccess();
    },
    onError: () => {
      handleSaveError();
    },
  });

  const handleNext = () => {
    form
      .validateFields()
      .then((values) => {
        setStep1Data((prev) => ({ ...prev, ...values }));
        setCurrent(current + 1);
      })
      .catch((info) => console.log('Validation Failed:', info));
  };

  const handlePrev = () => {
    setCurrent(current - 1);
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        const finalData = { ...step1Data, ...values };
        finalData.progress = record.progress;
        setformData(finalData);
        updateEdgeApplication({ recordId: record.id, body: finalData });
      })
      .catch((info) => {
        if (info.errorFields && info.errorFields.length > 0) {
          let errors = [];
          const errorFieldsName = info.errorFields.map((errorField) => errorField.name);
          if (errorFieldsName.every((field) => field in requiredFieldLabels)) {
            errorFieldsName.forEach((errorField) => {
              if (errorField in requiredFieldLabels) {
                const label = requiredFieldLabels[errorField];
                errors.push(label);
                const finalData = { ...step1Data, ...info.values };
                finalData.progress = record.progress;
                setformData(finalData);
                setformErrorFields(errors);
                setshowAlertModal(true);
              }
            });
          }
        }
      });
  };

  const steps = [
    {
      title: 'Applications',
      content: <Step1FormItems form={form} handleNext={handleNext} solutionStates={solutionStates} record={record} />,
    },
    {
      title: 'Business detail',
      content: (
        <Step2FormItems
          form={form}
          handleSubmit={handleSubmit}
          handlePrev={handlePrev}
          formData={step1Data}
          stepProgress={record?.progress}
          solutionStates={solutionStates}
          record={record}
        />
      ),
    },
  ];

  useEffect(() => {
    if (isLoading) {
      handleSave();
    }
  }, [isLoading]);

  const handleCancel = () => {
    setshowAlertModal(false);
  };

  return (
    <div>
      <Steps current={current} responsive={true} type="navigation">
        {steps.map((step, index) => {
          return <Step key={index} title={step.title}></Step>;
        })}
      </Steps>
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        onValuesChange={onValuesChange}
        initialValues={record ? record : null}
      >
        <div className="grid grid-rows-1">
          <div className="py-4 px-4">{steps[current].content}</div>
        </div>
      </Form>
      <Modal
        title="Confirm"
        visible={showAlertModal}
        onCancel={handleCancel}
        destroyOnClose={true}
        maskClosable={false}
        footer={[
          <Button
            key="cancel"
            type="primary"
            onClick={() => {
              setshowAlertModal(false);
            }}
          >
            Cancel
          </Button>,
          <Button
            key="confirm"
            type="primary"
            onClick={() => {
              updateEdgeApplication({ recordId: record.id, body: formData });
            }}
          >
            Confirm
          </Button>,
        ]}
      >
        <p>Please check the following fields :</p>
        <ul className="ml-5 list-decimal">
          {formErrorFields.map((message, index) => (
            <li className="font-semibold" key={index}>
              {message}
            </li>
          ))}
        </ul>
        <p>Do you want to proceed anyway ?</p>
      </Modal>
    </div>
  );
}
