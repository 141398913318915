/* eslint-disable operator-linebreak */
import React, { useState, useEffect } from 'react';
import { Button, Divider, Form, Input, Switch } from 'antd';
import { useQuery } from 'react-query';
import { getDeveloperOptions, getProjectOptions } from '@root/services/sdeInventories/sde.edge-application.service.js';
import CustomSelect from '@root/components/atoms/CustomSelect.jsx';
import CustomDatePicker from '@root/components/atoms/CustomDatePicker.jsx';

export default function Step2FormItems({ form, handleSubmit, handlePrev, formData, stepProgress, record }) {
  const [solutionList, setSolutionList] = useState([]);
  const [selectedDeveloper, setSelectedDeveloper] = useState(null);
  const [requestProjectOptionsTrigger, setRequestProjectOptionsTrigger] = useState(false);
  const { data: developerOptions } = useQuery('get_developer_options', getDeveloperOptions, {});

  const { data: projectOptions } = useQuery(
    ['get_project_options', { selectedDeveloper: form.getFieldValue('developer') }],
    getProjectOptions,
    {
      onSuccess: (data) => {
        setRequestProjectOptionsTrigger(false);
      },
      enabled: record ? true : requestProjectOptionsTrigger,
    },
  );

  const businessUnitOptions = [
    {
      value: 'SSB',
      label: 'SSB',
    },
    {
      value: 'MIND',
      label: 'MIND',
    },
  ];

  const statusOptions = [
    {
      value: 'Inactive',
      label: 'Inactive',
    },
    {
      value: 'To be installed',
      label: 'To be install',
    },
    {
      value: 'Active',
      label: 'Active',
    },
    {
      value: 'Suspended',
      label: 'Suspended',
    },
  ];

  const monitoringStatusOptions = [
    {
      value: 'No_Monitor',
      label: 'No Monitor',
    },
    {
      value: 'Monitor',
      label: 'Monitor',
    },
  ];

  const handleSelectDeveloper = (value) => {
    form.setFieldsValue({ project: null });
    setSelectedDeveloper(value);
    setRequestProjectOptionsTrigger(true);
  };

  useEffect(() => {
    function listAllSolutions() {
      const allApplicationName = formData.applications.map((item) => item.applicationType);
      setSolutionList(allApplicationName);
    }
    listAllSolutions();
  }, [formData]);

  return (
    <div>
      <div className="grid grid-cols-4 gap-2">
        <Form.Item
          className="col-span-2"
          name="businessUnit"
          label="Business Unit"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <CustomSelect options={businessUnitOptions} />
        </Form.Item>
        <Form.Item className="col-span-2" name="note" label="Note">
          <Input />
        </Form.Item>
        <Form.Item
          className="col-span-2"
          name="installedDate"
          label="Installed date"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <CustomDatePicker />
        </Form.Item>
        <Form.Item
          className="col-span-2"
          name="developer"
          label="Developer"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <CustomSelect options={developerOptions?.data} onChange={handleSelectDeveloper} />
        </Form.Item>
        <Form.Item
          className="col-span-2"
          name="project"
          label="Project"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <CustomSelect
            options={projectOptions?.data}
            disable={record && record.developer ? false : selectedDeveloper ? false : true}
          />
        </Form.Item>
        <Form.Item
          className="col-span-2"
          name="status"
          label="Status"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <CustomSelect options={statusOptions} />
        </Form.Item>
        <Form.Item
          className="col-span-2"
          name="monitoringStatus"
          label="Monitoring status"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <CustomSelect options={monitoringStatusOptions} />
        </Form.Item>
        <Form.Item
          className="col-span-2"
          name="virtual"
          label="Virtual"
          // rules={[
          //   {
          //     required: true,
          //   },
          // ]}
        >
          <Switch defaultChecked={false} checked={form.getFieldValue('virtual')} />
        </Form.Item>
        <Form.Item
          className="col-span-2"
          name="reference"
          label="Reference"
          rules={[
            {
              required:
                stepProgress === 'STEP_2' &&
                (solutionList.includes('aaq') || solutionList.includes('aaf') || solutionList.includes('aafq'))
                  ? true
                  : false,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          className="col-span-2"
          name="externalIdentity"
          label="External Identity"
          rules={[
            {
              required:
                stepProgress === 'STEP_2' &&
                (solutionList.includes('aaq') || solutionList.includes('aaf') || solutionList.includes('aafq'))
                  ? true
                  : false,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          className="col-span-2"
          name="integrationCode"
          label="Integration code"
          rules={[
            {
              required:
                stepProgress === 'STEP_2' &&
                (solutionList.includes('aaq') || solutionList.includes('aaf') || solutionList.includes('aafq'))
                  ? true
                  : false,
            },
          ]}
        >
          <Input />
        </Form.Item>

        {stepProgress === 'INITIAL' ? (
          <>
            {' '}
            <Divider className="col-span-4" orientation="left">
              WiFi
            </Divider>
            <Form.Item
              className="col-span-2"
              name="wifiSSID"
              label="SSID"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              className="col-span-2"
              name="wifiPassword"
              label="password"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input.Password />
            </Form.Item>{' '}
          </>
        ) : null}
      </div>
      <div className="flex flex-row-reverse gap-4">
        <Button type="primary" onClick={handleSubmit}>
          Save
        </Button>
        <Button type="primary" onClick={handlePrev}>
          Prev
        </Button>
      </div>
    </div>
  );
}
