import React, { useEffect, useMemo, useState } from 'react';
import { Card, Col, Row, Typography } from 'antd';
import PropTypes from 'prop-types';
import { useEdgeApplication } from '@store/edgeApplication/hook';
import moment from 'moment';
import { EdgeStatusEnum } from '@services/edgeApplication';

const { Text } = Typography;
const handleEmpty = (val) => val || '-';
const handleEmptyDate = (val, format = dateFormat) => (val ? moment(val).format(format) : '-');
const dateFormat = 'YYYY-MM-DD';
const dateTimeFormat = 'YYYY-MM-DD HH:mm:ss';

moment.locale('en', {
  week: {
    dow: 1,
  },
});

const EdgeDeviceInfo = ({ currentTab }) => {
  const { dataEdge, edgeDashboard } = useEdgeApplication();

  const [deviceInfo, setDeviceInfo] = useState({});

  useEffect(() => {
    const edgeList = edgeDashboard?.applications || [];
    const findApp = (name) => edgeList.find((app) => app.application_type === name) || {};

    setDeviceInfo({
      aafData: findApp('ActiveAirFlow'),
      aaqData: findApp('ActiveAirQuality'),
    });
  }, [dataEdge, edgeDashboard]);

  const onlineStatus = useMemo(() => {
    return dataEdge?.solar_status || EdgeStatusEnum.offline;
  }, [dataEdge]);

  return (
    <Card title="Devices information">
      <Row gutter={[16, 32]}>
        <Col span={8}>Edge ID</Col>
        <Col span={16}>
          <Text strong>{handleEmpty(dataEdge?.edge_barcode)}</Text>
          <Text strong style={{ color: 'red', padding: 2 }}>
            {dataEdge?.virtual_edge ? '(Virtual EDGE)' : ''}
          </Text>
        </Col>

        <Col span={8}>Status</Col>
        <Col span={16}>
          <Text strong>{dataEdge?.status || 'Inactive'}</Text>
        </Col>

        <Col span={8}>Online status</Col>
        <Col span={16}>
          <Text strong style={{ color: onlineStatus === EdgeStatusEnum.offline ? 'red' : '' }}>
            {onlineStatus.charAt(0).toUpperCase() + onlineStatus.slice(1)}
          </Text>
        </Col>

        {/* <Col span={8}>Installation date</Col>
        <Col span={16}>
          <Text strong>{handleEmpty(dataEdge?.installed_date)}</Text>
        </Col> */}

        <Col span={8}>Last seen</Col>
        <Col span={16}>
          <Text strong>{handleEmptyDate(dataEdge?.last_seen, dateTimeFormat)}</Text>
        </Col>

        <Col span={8}>Storage Type</Col>
        <Col span={16}>
          <Text strong>{handleEmpty(dataEdge?.storage_type)}</Text>
        </Col>

        <Col span={8}>Delivery Status</Col>
        <Col span={16}>
          <Text strong>{handleEmpty(dataEdge?.delivery_status)}</Text>
        </Col>

        <Col span={8}>Reference</Col>
        <Col span={16}>
          <Text strong>{handleEmpty(dataEdge.reference)}</Text>
        </Col>

        <Col span={8}>Integration Code</Col>
        <Col span={16}>
          <Text strong>{handleEmpty(dataEdge.integration_code)}</Text>
        </Col>

        <EdgeSolutionInfo dataEdge={dataEdge} deviceInfo={deviceInfo} currentTab={currentTab} />
      </Row>
    </Card>
  );
};

EdgeDeviceInfo.propTypes = {
  currentTab: PropTypes.string,
};

export default EdgeDeviceInfo;

/**
 * @param dataEdge
 * @param deviceInfo
 * @param {'solar' | 'deebox' | 'aaq' | 'aaf' | string} currentTab
 * @returns {JSX.Element|string}
 * @constructor
 */
const EdgeSolutionInfo = ({ dataEdge = {}, deviceInfo, currentTab }) => {
  const { aafData, aaqData } = deviceInfo;

  const maPlanStatus = useMemo(() => {
    if (!dataEdge?.ma_plan_date) return 'Unknown';
    const today = moment().subtract(1, 'days');
    const maPlanDate = moment(dataEdge.ma_plan_date);
    const daysDiff = maPlanDate.diff(today, 'days');
    if (daysDiff > 30) {
      return 'Normal';
    }
    if (daysDiff <= 30 && daysDiff >= 0) {
      return 'Time to Clean';
    }
    if (daysDiff < 0) {
      return 'Delayed';
    }
    return 'Unknown';
  }, [dataEdge?.ma_plan_date]);

  switch (currentTab) {
    case 'solar':
      return (
        <>
          {/* <Col span={8}>Clean date</Col>
          <Col span={16}>
            <Text strong>{handleEmptyDate(dataEdge?.ma_plan_date)}</Text>
          </Col>
          <Col span={8}>MA plan status</Col>
          <Col span={16}>
            <Text strong>{maPlanStatus}</Text>
          </Col> */}
        </>
      );
    case 'deebox':
      return <></>;
    case '_aaq':
      return (
        <>
          <Col span={24}>Device Model</Col>
          <Col span={7} offset={1}>
            Edge
          </Col>
          <Col span={4}>
            <Text strong>{aaqData?.model}</Text>
          </Col>
          <Col span={7} offset={1}>
            Qty.
          </Col>
          <Col span={4}>
            <Text strong>{aaqData?.units}</Text>
          </Col>
          <Col span={7} offset={1}>
            Actuator
          </Col>
          <Col span={4}>
            <Text strong></Text>
          </Col>
          <Col span={7} offset={1}>
            Qty.
          </Col>
          <Col span={4}>
            <Text strong></Text>
          </Col>
          <Col span={7} offset={1}>
            SRTV
          </Col>
          <Col span={4}>
            <Text strong></Text>
          </Col>
          <Col span={7} offset={1}>
            Qty.
          </Col>
          <Col span={4}>
            <Text strong></Text>
          </Col>
          <Col span={7} offset={1}>
            CV
          </Col>
          <Col span={4}>
            <Text strong></Text>
          </Col>
          <Col span={7} offset={1}>
            Qty.
          </Col>
          <Col span={4}>
            <Text strong></Text>
          </Col>

          <Col span={8}>Install Contractor</Col>
          <Col span={16}>
            <Text strong></Text>
          </Col>

          <Col span={8}>SA Installer</Col>
          <Col span={16}>
            <Text strong></Text>
          </Col>

          <Col span={8}>Warranty End-Date</Col>
          <Col span={5}>
            <Text strong></Text>
          </Col>
          <Col span={7}>Days Left</Col>
          <Col span={4}>
            <Text strong></Text>
          </Col>

          <Col span={8}>Remark</Col>
          <Col span={16}>
            <Text strong></Text>
          </Col>
        </>
      );
    case '_aaf':
      return (
        <>
          <Col span={24}>Device Model</Col>
          <Col span={7} offset={1}>
            Edge
          </Col>
          <Col span={4}>
            <Text strong>{aafData?.model}</Text>
          </Col>
          <Col span={7} offset={1}>
            Qty.
          </Col>
          <Col span={4}>
            <Text strong>{aafData?.units}</Text>
          </Col>
          <Col span={7} offset={1}>
            Actuator
          </Col>
          <Col span={4}>
            <Text strong></Text>
          </Col>
          <Col span={7} offset={1}>
            Qty.
          </Col>
          <Col span={4}>
            <Text strong></Text>
          </Col>
          <Col span={7} offset={1}>
            SRTV
          </Col>
          <Col span={4}>
            <Text strong></Text>
          </Col>
          <Col span={7} offset={1}>
            Qty.
          </Col>
          <Col span={4}>
            <Text strong></Text>
          </Col>
          <Col span={7} offset={1}>
            CV
          </Col>
          <Col span={4}>
            <Text strong></Text>
          </Col>
          <Col span={7} offset={1}>
            Qty.
          </Col>
          <Col span={4}>
            <Text strong></Text>
          </Col>

          <Col span={8}>Install Contractor</Col>
          <Col span={16}>
            <Text strong></Text>
          </Col>

          <Col span={8}>SA Installer</Col>
          <Col span={16}>
            <Text strong></Text>
          </Col>

          <Col span={8}>Warranty End-Date</Col>
          <Col span={5}>
            <Text strong></Text>
          </Col>
          <Col span={7}>Days Left</Col>
          <Col span={4}>
            <Text strong></Text>
          </Col>

          <Col span={8}>Remark</Col>
          <Col span={16}>
            <Text strong></Text>
          </Col>
        </>
      );
    case 'aaq':
    case 'aaf':
    default:
      return <></>;
  }
};

EdgeSolutionInfo.propTypes = {
  dataEdge: PropTypes.object,
  deviceInfo: PropTypes.object,
  currentTab: PropTypes.string,
};
