import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import { getActuator } from '@root/services/sdeInventories/actuator.service.js';
import { getColumnSearchProps } from '@root/utils/columnSearchProps.js';
import { Table, Tag } from 'antd';
import React from 'react';
import { useQuery } from 'react-query';

export default function ActuatorDevicesTable() {
  const { data: actuatorData, isLoading } = useQuery('get_actuators', getActuator, {});

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };
  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const columnSearchProps = getColumnSearchProps(handleSearch, handleReset);

  const columns = [
    {
      title: 'Device barcode',
      width: 200,
      dataIndex: 'deviceBarcode',
      align: 'center',
      key: 'deviceBarcode',
      fixed: 'left',
      ...columnSearchProps('deviceBarcode'),
    },
    {
      title: 'Firmware version',
      width: 400,
      dataIndex: 'firmwareVersion',
      align: 'center',
      key: 'firmwareVersion',
      ...columnSearchProps('firmwareVersion'),
    },
    {
      title: 'Edge application',
      width: 400,
      dataIndex: 'edgeApplication',
      align: 'center',
      key: 'edgeApplication',
      render: (value) => {
        if (value) {
          return (
            <div>
              <Tag>{value}</Tag>
            </div>
          );
        } else {
          return <div>-</div>;
        }
      },
      ...columnSearchProps('edgeApplication'),
    },
    {
      title: 'Verified',
      width: 200,
      dataIndex: 'isVerified',
      align: 'center',
      key: 'isVerified',
      render: (value) => {
        if (value) {
          return <CheckCircleTwoTone twoToneColor="#52c41a" style={{ fontSize: '25px' }} />;
        } else {
          return <CloseCircleTwoTone twoToneColor="#f5222d" style={{ fontSize: '25px' }} />;
        }
      },
    },
    // {
    //   title: 'Updated at',
    //   dataIndex: 'updatedAt',
    //   align: 'center',
    // },
    // {
    //   title: 'Updated by',
    //   dataIndex: 'updatedBy',
    //   align: 'center',

    //   filters: [
    //     {
    //       text: 'London',
    //       value: 'London',
    //     },
    //     {
    //       text: 'New York',
    //       value: 'New York',
    //     },
    //   ],
    //   onFilter: (value, record) => record.address.indexOf(value) === 0,
    // },
    // {
    //   // title: 'Action',
    //   key: 'action',
    //   align: 'center',
    //   fixed: 'right',
    //   width: 135,
    //   render: (_, record) => <TableAction record={record} />,
    // },
  ];

  const onChange = (pagination, filters, sorter, extra) => {};
  return (
    <div>
      <Table
        columns={columns}
        dataSource={actuatorData?.data}
        onChange={onChange}
        scroll={{
          x: 1300,
        }}
        loading={isLoading}
      />
    </div>
  );
}
