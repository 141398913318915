import { getSDEEdgeApplication } from '@root/services/sdeInventories/sde.edge-application.service.js';
import { Table, Tag } from 'antd';
import React from 'react';
import { useQuery } from 'react-query';
import StepButton from './StepButton.jsx';
import TableAction from './TableAction.jsx';
import { getColumnSearchProps } from '@root/utils/columnSearchProps.js';

export default function EdgeApplicationTable() {
  const { data: sdeEdgeApplications, isLoading } = useQuery('get_sde_edge_application', getSDEEdgeApplication, {
    onSuccess: (data) => {},
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };
  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const columnSearchProps = getColumnSearchProps(handleSearch, handleReset);

  const columns = [
    {
      title: 'Edge',
      width: 100,
      dataIndex: 'edgeId',
      align: 'center',
      key: 'edgeId',
      fixed: 'left',
      ...columnSearchProps('edgeId'),
    },
    {
      title: 'Progress',
      dataIndex: 'progress',
      align: 'center',
      fixed: 'left',
      width: 75,
      render: (_, record) => (
        <>
          <StepButton record={record} />
        </>
      ),
    },
    {
      title: 'Applications',
      width: 200,
      dataIndex: 'solutionList',
      align: 'center',
      key: 'solutionList',
      render: (value) => {
        if (value) {
          return (
            <div>
              {value.map((solution, index) => {
                return <Tag key={index}>{solution.toUpperCase()}</Tag>;
              })}
            </div>
          );
        } else {
          return <div>-</div>;
        }
      },
    },
    // {
    //   title: 'Child devices',
    //   width: 200,
    //   dataIndex: 'child_device',
    //   align: 'center',
    //   key: 'child_device',
    //   render: (value) => {
    //     if (value) {
    //       return <div>{value}</div>;
    //     } else {
    //       return <div>-</div>;
    //     }
    //   },
    // },
    {
      title: 'Updated at',
      dataIndex: 'updatedAt',
      width: 150,
      align: 'center',
    },
    // {
    //   title: 'Updated by',
    //   dataIndex: 'updatedBy',
    //   align: 'center',

    //   filters: [
    //     {
    //       text: 'London',
    //       value: 'London',
    //     },
    //     {
    //       text: 'New York',
    //       value: 'New York',
    //     },
    //   ],
    //   onFilter: (value, record) => record.address.indexOf(value) === 0,
    // },
    {
      // title: 'Action',
      key: 'action',
      align: 'center',
      fixed: 'right',
      width: 35,
      render: (_, record) => <TableAction record={record} />,
    },
  ];

  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  };
  return (
    <div>
      <Table
        columns={columns}
        dataSource={sdeEdgeApplications?.data}
        onChange={onChange}
        scroll={{
          x: 1300,
        }}
        loading={isLoading}
      />
    </div>
  );
}
