import React from 'react';
import { Button, Form, Input } from 'antd';
import SolutionFormList from './SolutionFormList.jsx';

export default function Step1FormItems({ handleNext, form, solutionStates, record }) {
  return (
    <>
      <div className="grid grid-cols-4 gap-2">
        <Form.Item
          className="col-span-2"
          name="edgeId"
          label="Edge"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input readOnly={true} />
        </Form.Item>

        <SolutionFormList form={form} solutionStates={solutionStates} record={record} />
      </div>
      <div className="flex flex-row-reverse">
        <Button type="primary" onClick={handleNext}>
          Next
        </Button>
      </div>
    </>
  );
}
