import React from 'react';
import EdgeApplicationTable from './EdgeApplicationTable.jsx';

export default function EdgeApplication() {
  return (
    <div>
      <div className="mt-12">
        <EdgeApplicationTable />
      </div>
    </div>
  );
}
