import { Button, Upload, message } from 'antd';
import React from 'react';
import { UploadOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx';
import Papa from 'papaparse';

export default function UploadButton({ handleFileData }) {
  const handleExcelFileUpload = (file) => {
    const isXlsx = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    if (!isXlsx) {
      message.error('You can only upload .xlsx files!');
      return Upload.LIST_IGNORE;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { raw: false });
      handleFileData(jsonData);
    };

    reader.onerror = () => {
      message.error('Error reading file!');
    };

    reader.readAsArrayBuffer(file);
    return false;
  };

  const handleCSVFileUpload = (file) => {
    if (file.type !== 'text/csv' && !file.name.endsWith('.csv')) {
      message.error('Please upload a valid CSV file');
      return false;
    }

    const reader = new FileReader();
    reader.onload = async (event) => {
      const csvString = event.target?.result;
      Papa.parse(csvString, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          const jsonData = results.data;
          handleFileData(jsonData);
        },
        error: (error) => {
          console.log(`Error parsing file: ${error.message}`);
        },
      });
    };
    reader.readAsText(file);
    return false;
  };

  const beforeUpload = (file) => {
    if (file.type === 'text/csv' || file.name.endsWith('.csv')) {
      return handleCSVFileUpload(file);
    }
    if (
      file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      file.name.endsWith('.xlsx')
    ) {
      return handleExcelFileUpload(file);
    }
    message.error('You can only upload CSV or XLSX files!');
    return Upload.LIST_IGNORE; // Prevent invalid files from being added
  };

  return (
    <div>
      <Upload maxCount={1} accept=".csv, .xlsx" beforeUpload={beforeUpload}>
        <Button icon={<UploadOutlined />}>Select Excel File</Button>
      </Upload>
    </div>
  );
}
