import { MoreOutlined } from '@ant-design/icons';
import StatusIcon from '@components/atoms/statusIcon';
import JiraCard from '@root/components/atoms/jiraCard';
import Table from '@components/atoms/table';
import { canViewEdgeAppSolution, hasAccess } from '@root/utils/permission';
import { EdgeStatusEnum, getMaStatus, MAStatusEnum } from '@services/edgeApplication';
import { useAuth } from '@store/auth/hook';
import { Dropdown, Menu, Modal, Row, Space, Tag, Tooltip, Typography, Popover, Button } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import { array, bool, func, object } from 'prop-types';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { QRCodeCanvas } from 'qrcode.react';
import QrCodeImg from './components/QrCodeImg';
import { useGlobalHook } from '@root/store/global/globalHook.js';

const { Text } = Typography;

const textDeviceKey = {
  solar: 'Solar Roof',
  deebox: 'Deebox',
  aaf: 'AAF',
  aaq: 'AAQ',
  aafq: 'AAF, AAQ',
  ev: 'ev',
};

const renderJiraCardColumn = (jira_card_number, data) => {
  if (data.deployment_status === null || data.deployment_status.toLowerCase() !== 'fail') {
    return null;
  }

  return (
    <Row justify="center">
      <JiraCard
        isActive={data.jira_card_number != null}
        edgeId={data.edge_barcode}
        jiraTicket={data.jira_card_number}
      />
    </Row>
  );
};

const columns = ({ handleShowQR, sortOrder, user }) => {
  const { edgeApplicationFilters } = useGlobalHook();
  const [isFilterDeploymentFail, setisFilterDeploymentFail] = useState(false);

  useEffect(() => {
    setisFilterDeploymentFail(edgeApplicationFilters.deploymentStatus === 'Fail');
  }, [edgeApplicationFilters]);

  return [
    {
      title: 'Online Status',
      dataIndex: 'solar_status',
      key: 'solar_status',
      render: (val) => {
        const [label, color] = (() => {
          const status = val.toLowerCase();
          if (status === EdgeStatusEnum.online) {
            return ['Online', 'success'];
          }
          if (status === EdgeStatusEnum.offline) {
            return ['Offline', 'error'];
          }
          return ['Data Loss', 'warning'];
        })();
        return (
          <Row justify="center">
            <Tooltip title={label}>
              <StatusIcon status={color} />
            </Tooltip>
          </Row>
        );
      },
    },
    {
      title: 'Sensor Status',
      dataIndex: 'sensor_status',
      key: 'sensor_status',
      render: (val) => (
        <Row justify="center">
          <Tooltip title={val ? 'Healthy' : 'Unhealthy'}>
            <StatusIcon status={val ? 'success' : 'error'} />
          </Tooltip>
        </Row>
      ),
    },
    {
      title: 'Device Status',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      sortOrder: sortOrder('status'),
      render: (val) => val || 'Inactive',
    },

    {
      title: 'Ref',
      dataIndex: 'edge_reference',
      key: 'edge_reference',
      width: 75,
      sorter: true,
      sortOrder: sortOrder('edge_reference'),
      render: (record) => {
        return !record?.trim() ? '-' : record;
      },
    },
    {
      title: 'Integration code',
      key: 'integration_code',
      width: 75,
      sorter: true,
      sortOrder: sortOrder('integration_code'),
      render: (record) => {
        return !record?.integration_code?.trim() ? '-' : record.integration_code;
      },
    },
    {
      title: 'QR',
      dataIndex: '',
      key: 'qr-code',
      render: ({ edge_barcode, first_name, last_name }) => {
        return (
          <div style={{ cursor: 'pointer' }} onClick={() => handleShowQR({ edge_barcode, first_name, last_name })}>
            <QRCodeCanvas size={40} value={edge_barcode} />
          </div>
        );
      },
    },
    {
      title: 'Edge ID',
      dataIndex: 'edge_barcode',
      key: 'edge_barcode',
      sorter: true,
      sortOrder: sortOrder('edge_barcode'),
    },
    {
      title: 'Customer Name',
      key: 'customer_name',
      sortOrder: sortOrder('customer_name'),
      render: (record) => {
        let customer = {};
        if (record?.owned_customer?.[0]?.id) {
          customer = record?.owned_customer?.[0];
          // console.log('owned', customer);
        } else if (record?.customers?.[0]?.id) {
          customer = record?.customers?.[0];
          // console.log('mem', customer);
        }
        const { id, title_name, first_name, last_name } = customer;
        return id ? [title_name, first_name, last_name].filter(Boolean).join(' ') : '-';
      },
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      render: (val) => {
        if (val) {
          return val;
        }
        return 'test';
      },
    },
    {
      title: 'Phone',
      key: 'telephone',
      sorter: true,
      sortOrder: sortOrder('telephone'),
      render: (record) => {
        let customer = {};
        if (record?.owned_customer?.[0]?.id) {
          customer = record?.owned_customer?.[0];
        } else if (record?.customers?.[0]?.id) {
          customer = record?.customers?.[0];
        }
        const { telephone } = customer;
        return telephone ?? '-';
      },
    },
    {
      title: 'Installed',
      width: 130,
      dataIndex: 'installed_date',
      key: 'installed_date',
      sorter: true,
      sortOrder: sortOrder('installed_date'),
      render: (val) => (val ? moment(val).format('YYYY-MM-DD') : '-'),
    },
    {
      title: 'Storage Type',
      dataIndex: 'storage_type',
      key: 'storage_type',
      render: (val) => val || '-',
    },
    {
      title: 'MA Plan',
      width: 130,
      dataIndex: 'ma_plan_date',
      key: 'ma_plan_date',
      render: (val) => {
        if (!val) return '-';

        const date = dayjs(val);
        const status = getMaStatus(date);
        const type = (() => {
          if (status === MAStatusEnum.normal) {
            return 'success';
          } else if (status === MAStatusEnum.clean) {
            return 'warning';
          }
          return 'danger';
        })();

        return <Text type={type}>{date.format('YYYY-MM-DD')}</Text>;
      },
    },
    {
      title: 'Note',
      dataIndex: 'note',
      key: 'note',
      sorter: true,
      sortOrder: sortOrder('note'),
      render: (val) => val || '-',
    },
    {
      title: 'Last Seen',
      width: 130,
      dataIndex: 'last_seen',
      key: 'last_seen',
      sorter: true,
      sortOrder: sortOrder('last_seen'),
      render: (val) => (val ? moment(val).format('YYYY-MM-DD HH:mm:ss') : '-'),
    },
    {
      title: 'Solutions',
      key: 'solutions',
      render: (record) => (
        <Space size={5} style={{ display: 'flex', flexWrap: 'wrap' }}>
          {record.applications.map((app, key) => (
            <Tag key={key}>{textDeviceKey[app.application_name] || app.application_name}</Tag>
          ))}
        </Space>
      ),
    },
    {
      title: 'Deployment Status',
      dataIndex: 'deployment_status',
      key: 'deployment_status',
      render: (val) => val || '-',
    },
    {
      title: 'Thing Group',
      dataIndex: 'thing_group',
      key: 'thing_group',
      render: (val) => val || '-',
    },
    hasAccess(user, 'edge-applications', 'read-child') && {
      width: 110,
      render: (record) => <Link to={`/dashboard/edge-applications/${record.edge_barcode}`}>VIEW SITE</Link>,
    },
    {
      key: 'jira_card_number',
      dataIndex: 'jira_card_number',
      sorter: isFilterDeploymentFail ? true : null,
      sortOrder: sortOrder('jira_card_number'),
      sortDirections: ['descend', 'ascend'],
      render: renderJiraCardColumn,
    },
    hasAccess(user, 'edge-applications', 'read-child') && {
      key: 'action',
      width: 80,
      render: (record) => {
        const { applications } = record;
        const devices = applications.filter((device) => canViewEdgeAppSolution(user, device.application_name));
        const isEmptyDevice = devices.length === 0;
        const menu = (
          <Menu>
            {devices.map((val) => (
              <Menu.Item key={val.id}>
                <Link to={`/dashboard/edge-applications/${record.edge_barcode}?activeTab=${val.application_name}`}>
                  {textDeviceKey[val.application_name] || val.application_name}
                </Link>
              </Menu.Item>
            ))}
          </Menu>
        );

        return (
          <Row justify="center">
            <Dropdown overlay={menu} placement="bottomRight" disabled={isEmptyDevice}>
              <MoreOutlined style={{ fontSize: 24, color: isEmptyDevice ? '#ddd' : 'unset', cursor: 'pointer' }} />
            </Dropdown>
          </Row>
        );
      },
    },
  ].filter((x) => x);
};
const EdgeTable = (props) => {
  const [selectedQR, setSelectedQR] = useState(null);

  const { user } = useAuth();
  const { items, loading, onChange, pagination, filters } = props;
  const [sortedInfo, setSortedInfo] = useState({});

  const handleShowQR = (values) => {
    setSelectedQR(values);
  };

  const renderCustomerName = (first_name, last_name) => {
    return `${first_name ?? ''} ${last_name ?? ''}`;
  };

  const sortOrder = (col) => (sortedInfo.columnKey === col ? sortedInfo.order : null);

  return (
    <div>
      {selectedQR && (
        <Modal footer={null} onCancel={() => setSelectedQR(null)} visible={!!selectedQR}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              padding: 40,
            }}
          >
            <QrCodeImg
              width="400"
              height="540"
              value={{
                edge_barcode: selectedQR.edge_barcode,
                first_name: selectedQR.first_name,
                last_name: selectedQR.last_name,
              }}
            ></QrCodeImg>
          </div>
        </Modal>
      )}
      <Table
        columns={columns({ handleShowQR, sortOrder, user })}
        dataSource={items}
        rowKey="index"
        scroll={{
          x: 240,
        }}
        loading={{
          spinning: loading,
          tip: 'Loading...',
        }}
        onChange={(pagination, filters, sorter) => {
          onChange(pagination, sorter);
          setSortedInfo(sorter);
        }}
        pagination={{ ...pagination, current: filters.page, showSizeChanger: true }}
      />
    </div>
  );
};

EdgeTable.propTypes = {
  items: array,
  loading: bool,
  onChange: func,
  pagination: object,
  filters: object,
};

EdgeTable.defaultProps = {
  items: [],
  loading: false,
  onChange: () => {},
  pagination: {},
};

export default EdgeTable;
