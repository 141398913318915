import { instance } from '@root/utils/axios';

export const batchInsertActuator = async (newActuator) => {
  const res = await instance.post('/child-devices/batchInsert', newActuator);
  return res;
};

export const getActuator = async () => {
  const res = await instance.get('/child-devices/actuators');
  return res;
};
