import { instance } from '@root/utils/axios';

export const batchInsertEdge = async (newEdges) => {
  const res = await instance.post('/edges/batchInsert', newEdges);
  return res;
};


export const getEdges = async () => {
  const res = await instance.get('/edges');
  return res;
};

export const getAvailableEdges = async () => {
  const res = await instance.get('/sde-edge-applications/edgeOptions');
  return res;
};


