import React, { useEffect, useState } from 'react';
import Table from '@components/atoms/table';
import { useEdgeApplication } from '@store/edgeApplication/hook';
import { InfoCircleOutlined } from '@ant-design/icons';
import DeviceFeatureSpecsModal from '../../modals/deviceFeatureSpecsModal';
import { startCase } from 'lodash';

const AssociateDeviceTable = () => {
  const { dataEdge } = useEdgeApplication();

  const [items, setItems] = useState([]);
  const [page, setPage] = React.useState(1);
  const [isShowFeatureSpecModal, setIsShowFeatureSpecModal] = useState(false);
  const [activeBomSpecModalId, setActiveBomSpecModalId] = useState(-1);
  useEffect(() => {
    if (!dataEdge?.application_devices) return;
    const devices = dataEdge.application_devices.reduce((prev, current) => [...prev, ...current.devices], []);
    setItems(devices);
  }, []);

  const columns = [
    {
      title: 'No.',
      dataIndex: 'id',
      key: 'id',
      render: (value, item, index) => (page - 1) * 10 + index + 1 + '.',
    },
    {
      title: 'Device ID',
      render: (item) => {
        return (
          <>
            {item.device_barcode} ({item.id}){' '}
            {item.bom != null && (
              <InfoCircleOutlined className="device-spec-popup-trigger" style={{ cursor: 'pointer' }} />
            )}
          </>
        );
      },
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            if (ev.target.closest('.device-spec-popup-trigger')) {
              setActiveBomSpecModalId(record.bom.id);
              setIsShowFeatureSpecModal(true);
            }
          },
        };
      },
    },
    {
      title: 'Model',
      dataIndex: 'model',
      key: 'model',
      render: (item) => {
        return item ? item : <span style={{ 'text-align': 'center' }}>-</span>;
      },
    },
    {
      title: 'Type',
      dataIndex: 'device_type',
      key: 'device_type',
    },
    {
      title: 'Location',
      render: (item) => `${item.location} (${item.controller_type})`,
    },
    {
      title: 'Set',
      dataIndex: 'set',
      key: 'set',
    },
    {
      title: 'Zone',
      dataIndex: 'zone',
      key: 'zone',
    },
    {
      title: 'Room',
      dataIndex: 'room_name',
      key: 'room_name',
    },
    {
      title: 'Installed Date',
      dataIndex: 'installed_date',
      key: 'installed_date',
      render: (item) => {
        return item ? item : '-';
      },
    },
    {
      title: 'Installed By',
      render: (item) => {
        return item?.edge_associate_device_detail?.installed_by
          ? startCase(item.edge_associate_device_detail.installed_by)
          : '-';
      },
    },
    {
      title: 'Warranty',
      dataIndex: 'warranty',
      key: 'warranty',
      render: (item) => {
        return item ? 'Yes' : 'No';
      },
    },
    {
      title: 'Warranty Expiry Date',
      dataIndex: 'warranty_expiry_date',
      key: 'warranty_expiry_date',
      render: (item) => {
        return item ? item : '-';
      },
    },
    {
      title: 'Firmware version',
      dataIndex: 'firmware_version',
      key: 'firmware_version',
    },
    {
      title: 'Health Status',
      render: (item) => {
        return (
          <span
            style={{
              color: item.health_status && (item.health_status + '').toLowerCase() == 'healthy' ? 'green' : 'red',
            }}
          >
            {item.health_status}
          </span>
        );
      },
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={items}
        rowKey="id"
        pagination={{ total: items.length, pageSize: 10, onChange: setPage }}
        scroll={{
          x: 1300,
        }}
      />
      <DeviceFeatureSpecsModal
        visible={isShowFeatureSpecModal}
        onSubmit={() => setIsShowFeatureSpecModal(false)}
        onCancel={() => setIsShowFeatureSpecModal(false)}
        value={''}
        bomId={activeBomSpecModalId}
      />
    </>
  );
};

export default AssociateDeviceTable;
