import { DatePicker } from 'antd';
import moment from 'moment';
import React from 'react';

export default function CustomDatePicker({ value, onChange, placeholder }) {
  const handleChange = (date, dateString) => {
    onChange(dateString);
  };
  return (
    <DatePicker
      value={value ? moment(value) : null}
      onChange={handleChange}
      placeholder={placeholder}
      style={{ width: '100%' }}
    ></DatePicker>
  );
}
