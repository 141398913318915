import { instance } from '@root/utils/axios';

export const batchInsertSensor= async (newSensor) => {
  const res = await instance.post('/child-devices/batchInsert', newSensor);
  return res;
};


export const getSensor = async () => {
  const res = await instance.get('/child-devices/sensors');
  return res;
};


