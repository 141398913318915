import { CloudSyncOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import { syncToEP } from '@root/services/sdeInventories/sde.edge-application.service.js';
import { Button, Modal } from 'antd';
import React, { useState } from 'react';
import { useMutation } from 'react-query';
import ConfirmSyncToEP from './ConfirmSyncToEP.jsx';

export default function TableAction({ record }) {
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const { mutate: syncToEPRequest, isLoading } = useMutation(syncToEP, {
    onSuccess: () => {
      setShowConfirmModal(false);
      const successModal = Modal.success({
        title: `Save ${record.edgeId} successfully`,
      });
      setTimeout(() => {
        successModal.destroy();
      }, 3000);
    },
    onError: () => {
      setShowConfirmModal(false);
      const failModal = Modal.error({
        title: `Save ${record.edgeId} fail`,
      });
    },
  });

  const handleCancel = () => {
    setShowConfirmModal(false);
  };

  return (
    <div className="flex flex-row gap-2 justify-end">
      {record.progress !== 'INITIAL' ? (
        <Button
          style={{
            borderColor: '#9bdb76',
            background: '#e7f5dc',
            color: '#58c22b',
          }}
          shape="circle"
          icon={<CloudSyncOutlined />}
          onClick={() => {
            setShowConfirmModal(true);
          }}
        />
      ) : null}

      {/* <Button
        style={{
          borderColor: '#91d5ff',
          background: '#e6f7ff',
          color: '#1890ff',
        }}
        shape="circle"
        icon={<SearchOutlined />}
      /> */}
      {/* <Button
        style={{
          borderColor: '#91d5ff',
          background: '#e6f7ff',
          color: '#1890ff',
        }}
        shape="circle"
        icon={<EditOutlined />}
      /> */}

      <Modal
        title={record.edgeId}
        visible={showConfirmModal}
        onCancel={handleCancel}
        destroyOnClose={true}
        maskClosable={false}
        footer={[
          <Button
            key="cancel"
            type="primary"
            onClick={() => {
              setShowConfirmModal(false);
            }}
          >
            Cancel
          </Button>,
          <Button
            key="confirm"
            type="primary"
            onClick={() => {
              syncToEPRequest({ id: record.id });
            }}
          >
            Confirm
          </Button>,
        ]}
      >
        {/* <EdgeApplicationDetailModal record={record} /> */}
        <ConfirmSyncToEP record={record} />
      </Modal>
    </div>
  );
}
