import { batchInsertActuator } from '@root/services/sdeInventories/actuator.service.js';
import { Button, Modal, Divider } from 'antd';
import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import UploadButton from '../UploadButton.jsx';
import ActuatorDevicesTable from './ActuatorDevicesTable.jsx';

const actuatorKeysToCheck = ['DeviceID', 'Firmware_Version', 'DeviceType', 'Status'];

export default function ActuatorDevices() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newActuators, setNewActuators] = useState(null);
  const [invalidDatas, setInvalidDatas] = useState([]);
  const [errorText, setErrorText] = useState(null);

  const queryClient = useQueryClient();

  const mutation = useMutation(batchInsertActuator, {
    onSuccess: () => {
      setIsModalOpen(false);
      const successModal = Modal.success({
        title: 'Import Actuators Successfully.',
      });
      queryClient.invalidateQueries(['get_actuators']);
      setTimeout(() => {
        successModal.destroy();
      }, 3000);
    },
  });

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    mutation.mutate(newActuators);
    setNewActuators(null);
  };
  const handleCancel = () => {
    setInvalidDatas([]);
    setNewActuators(null);
    setErrorText(null);
    setIsModalOpen(false);
  };
  const handleFileData = (fileData) => {
    if (fileData.every((device) => actuatorKeysToCheck.every((key) => key in device))) {
      if (fileData.every((device) => device.DeviceType.toLowerCase().includes('actuator'))) {
        let fomattedData = { devices: [] };
        fileData.forEach((element) => {
          fomattedData.devices.push({
            deviceBarcode: element.DeviceID,
            firmwareVersion: element.Firmware_Version,
            category: element.DeviceType,
            isVerified: element.Status === 'GOOD' ? true : false,
          });
        });
        setErrorText(null);
        setInvalidDatas([]);
        setNewActuators(fomattedData);
      } else {
        setErrorText('File data invalid, Please check records below:');
        const invalidData = fileData.filter((device) => !device.DeviceType.toLowerCase().includes('actuator'));
        setInvalidDatas(invalidData);
        setNewActuators(null);
      }
    } else {
      setInvalidDatas([]);
      setErrorText(
        `File data invalid, actuator data must include following columns: ${actuatorKeysToCheck.join(', ')}`,
      );
      setNewActuators(null);
    }
  };
  return (
    <div>
      <div className="flex flex-row-reverse mb-4">
        <Button type="primary" onClick={showModal}>
          Import
        </Button>
      </div>
      <div>
        <ActuatorDevicesTable />
      </div>
      <Modal
        title="Add Actuator device"
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        maskClosable={false}
        okText="Import"
        okButtonProps={{ disabled: newActuators === null }}
      >
        <UploadButton handleFileData={handleFileData} />
        {errorText && (
          <div className="mt-7">
            <Divider></Divider> <div className=" text-red-600 ">{errorText}</div>
          </div>
        )}
        {invalidDatas.length > 0 && (
          <div>
            <div>
              <ul className="ml-5 list-decimal mt-2">
                {invalidDatas.map((data, index) => (
                  <li key={index}>{data.DeviceID}</li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
}
