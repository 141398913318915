export const locationOptions = [
  {
    value: 'Indoor',
    label: 'Indoor',
  },
  {
    value: 'Outdoor',
    label: 'Outdoor',
  },
  {
    value: 'Attic',
    label: 'Attic',
  },
];
