import React from 'react';
import { Typography } from 'antd';
import InventoriesTab from '@root/components/sdeinventories/InventoriesTab.jsx';

const { Title } = Typography;

export default function SDEInventories() {
  return (
    <div>
      <Title level={2}>SDE Inventories</Title>
      <InventoriesTab/>
    </div>
  );
}
