import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Space } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useQuery } from 'react-query';
import {
  getActuatorDeviceBarcodeOptions,
  getActuatorTypeOptions,
} from '@root/services/sdeInventories/sde.edge-application.service.js';
import CustomSelect from '@root/components/atoms/CustomSelect.jsx';
import { controllerTypeOptions } from '@root/utils/controllerTypeOptions.js';
import { locationOptions } from '@root/utils/locationOptions.js';

export default function ActuatorFormList({ applicationName, fieldKey, form, isAddButtonDisabled, record }) {
  const [actuatorOptions, setActuatorOptions] = useState([]);
  const { data: deviceBarcodeOptions, refetch: reFetchActuatorBarcodeOptions } = useQuery(
    'get_actuator_device_barcode_options',
    getActuatorDeviceBarcodeOptions,
    {
      onSuccess: (data) => {
        const actuatorBarcodes = form
          .getFieldValue(['applications'])
          .flatMap((entry) => entry.actuators.map((actuator) => actuator?.deviceBarcode));

        let filteredActuatorOptions = data.data;

        record.applications.map((application) => {
          application.actuators.map((actuator) => {
            data.data.push({
              value: actuator.id,
              label: actuator.deviceBarcode,
              disabled: actuatorBarcodes.includes(actuator.deviceBarcode) ? true : false,
            });
            filteredActuatorOptions.push({
              value: actuator.id,
              label: actuator.deviceBarcode,
              disabled: actuatorBarcodes.includes(actuator.deviceBarcode) ? true : false,
            });
          });
        });

        data.data.forEach((item) => {
          if (actuatorBarcodes.includes(item.label) || actuatorBarcodes.includes(item.value)) {
            item.disabled = true;
          }
        });

        filteredActuatorOptions = filteredActuatorOptions.filter(
          (obj, index, self) => index === self.findIndex((o) => o.value === obj.value),
        );

        setActuatorOptions(filteredActuatorOptions);
      },
      enabled: false,
    },
  );

  const { data: actuatorTypeOptions, refetch: reFetchActuatorTypeOptions } = useQuery(
    ['get_actuator_type_options', form.getFieldValue(['applications', applicationName])?.applicationType],
    () => getActuatorTypeOptions(form.getFieldValue(['applications', applicationName])?.applicationType),
  );

  const handleDeviceBarcodeDropdownOpen = (open) => {
    if (open) {
      reFetchActuatorBarcodeOptions();
    }
  };

  const handleDeviceTypeDropdownOpen = (open) => {
    if (open) {
      reFetchActuatorTypeOptions();
    }
  };

  useEffect(() => {
    reFetchActuatorBarcodeOptions();
  }, []);

  return (
    <div className="col-span-4 pl-7">
      <div className="pb-[8px]">Actuators</div>
      <Form.List name={[applicationName, 'actuators']} initialValue={[]}>
        {(actuatorFields, { add: addDevice, remove: removeDevice }) => {
          return (
            <>
              {actuatorFields.map(
                ({ key: actuatorKey, name: actuatorName, fieldKey: actuatorFieldKey, ...actuatorRestField }, index) => {
                  return (
                    <Space key={actuatorKey} className="flex gap-2 items-baseline" align="base-line">
                      <Form.Item
                        {...actuatorRestField}
                        name={[actuatorName, 'deviceBarcode']}
                        fieldKey={[actuatorFieldKey, 'deviceBarcode']}
                        rules={[
                          {
                            required: true,
                            message: 'Device barcode is required',
                          },
                        ]}
                        className="w-[200px]"
                      >
                        <CustomSelect
                          options={actuatorOptions}
                          placeholder="Select device barcode"
                          onDropdownVisibleChange={handleDeviceBarcodeDropdownOpen}
                        />
                      </Form.Item>

                      <Form.Item
                        {...actuatorRestField}
                        name={[actuatorName, 'deviceType']}
                        fieldKey={[actuatorFieldKey, 'deviceType']}
                        rules={[
                          {
                            required: true,
                            message: 'Device type is required',
                          },
                        ]}
                        className="w-[200px]"
                      >
                        <CustomSelect
                          options={actuatorTypeOptions?.data}
                          placeholder="Select device type"
                          onDropdownVisibleChange={handleDeviceTypeDropdownOpen}
                        />
                      </Form.Item>
                      <Form.Item
                        {...actuatorRestField}
                        name={[actuatorName, 'controllerType']}
                        fieldKey={[actuatorFieldKey, 'controllerType']}
                        rules={[
                          {
                            required: true,
                            message: 'Controller type is required',
                          },
                        ]}
                        className="w-[200px]"
                      >
                        <CustomSelect options={controllerTypeOptions} placeholder="Select controller type" />
                      </Form.Item>
                      <Form.Item
                        {...actuatorRestField}
                        name={[actuatorName, 'location']}
                        fieldKey={[actuatorFieldKey, 'location']}
                        rules={[
                          {
                            required: true,
                            message: 'location is required',
                          },
                        ]}
                        className="w-[200px]"
                      >
                        <CustomSelect options={locationOptions} placeholder="Select location" />
                      </Form.Item>
                      <Form.Item
                        {...actuatorRestField}
                        name={[actuatorName, 'roomName']}
                        fieldKey={[actuatorFieldKey, 'roomName']}
                        rules={[
                          {
                            required: true,
                            message: 'Room name is required',
                          },
                        ]}
                      >
                        <Input placeholder="Room name" />
                      </Form.Item>

                      <div className="flex gap-2">
                        <MinusCircleOutlined onClick={() => removeDevice(actuatorName)} />
                      </div>
                    </Space>
                  );
                },
              )}
              <Form.Item>
                <Button
                  disabled={isAddButtonDisabled}
                  type="dashed"
                  onClick={() => {
                    addDevice();
                    // reFetchActuatorBarcodeOptions();
                  }}
                  icon={<PlusOutlined />}
                >
                  Add actuator
                </Button>
              </Form.Item>
            </>
          );
        }}
      </Form.List>
    </div>
  );
}
