import { Button, Modal, Spin } from 'antd';
import React, { useState } from 'react';
import AddEdgeApplication from './AddEdgeApplication.jsx';

const initial_stype = {
  color: '#8c8c8c',
  background: '#fafafa',
  borderColor: '#f0f0f0',
  borderWidth: '1.5px',
};

const step_1_stype = {
  color: '#fa8c16',
  background: '#fff7e6',
  borderColor: '#ffd591',
  borderWidth: '1.5px',
};
const step_2_stype = {
  color: '#1677ff',
  background: '#e6f4ff',
  borderColor: '#91caff',
  borderWidth: '1.5px',
};
const published_stype = {
  color: '#52c41a',
  background: '#f6ffed',
  borderColor: '#b7eb8f',
  borderWidth: '1px',
};

export default function StepButton({ record }) {
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onClick = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleSave = () => {
    setLoading(true);
  };

  const handleSaveSuccess = () => {
    setLoading(false);
    setIsModalOpen(false);
  };

  const handleSaveError = () => {
    setLoading(false);
    const failModal = Modal.error({
      title: 'Something went wrong.',
    });
  };

  return (
    <div className="drop-shadow-sm">
      <Spin spinning={loading}>
        <Button
          shape="round"
          style={
            record.progress === 'PUBLISHED'
              ? published_stype
              : record.progress === 'STEP_1'
              ? step_1_stype
              : record.progress === 'STEP_2'
              ? step_2_stype
              : initial_stype
          }
          disabled={record.progress === 'PUBLISHED' ? true : false}
          onClick={onClick}
        >
          <div className="font-semibold">{record.progress}</div>
        </Button>
        <Modal
          title="Edge Application"
          visible={isModalOpen}
          onCancel={handleCancel}
          destroyOnClose={true}
          maskClosable={false}
          width="90vw"
          footer={null}
        >
          <AddEdgeApplication
            record={record}
            handleSave={handleSave}
            handleSaveSuccess={handleSaveSuccess}
            handleSaveError={handleSaveError}
          />
        </Modal>
      </Spin>
    </div>
  );
}
