import { Select } from 'antd';
import React from 'react';

// antd select with searchable label
export default function CustomSelect({
  options,
  value,
  placeholder,
  onChange,
  onDropdownVisibleChange,
  disable = false,
}) {
  return (
    <Select
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      options={options}
      showSearch
      optionFilterProp="label"
      filterOption={true}
      disabled={disable}
      style={{ width: '100%' }}
      optionLabelProp="label"
      onDropdownVisibleChange={onDropdownVisibleChange}
    >
      CustomSelect
    </Select>
  );
}
