import { Tabs } from 'antd';
import React, { useState } from 'react';
import EdgeApplication from './edge-application/EdgeApplication.jsx';
import EdgeDevices from './edge-device/EdgeDevices.jsx';
import SensorDevices from './sensor-device/SensorDevices.jsx';
import ActuatorDevices from './actuator-device/ActuatorDevices.jsx';

export default function InventoriesTab() {
  const [activeKey, setActiveKey] = useState('edge-application');

  const handleTabChange = (key) => {
    setActiveKey(key);
  };
  return (
    <div>
      <Tabs activeKey={activeKey} onChange={handleTabChange} destroyInactiveTabPane>
        <Tabs.TabPane tab="Edge Application" key="edge-application">
          <EdgeApplication />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Edge devices" key="edge-devices">
          <EdgeDevices />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Actuator devices" key="actuator-devices">
          <ActuatorDevices />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Sensor devices" key="sensor-devices">
          <SensorDevices />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}
