import { Tag } from 'antd';
import React from 'react';

export default function DeploymentStatus({ status }) {
  return (
    <div>
      <Tag
        className="font-semibold"
        color={
          status === 'SUCCEEDED' ?
            'success' :
            status === 'FAIL' ?
              'error' :
              status === 'QUEUE' ?
                'warning' :
                'processing'
        }
      >
        {status}
      </Tag>
    </div>
  );
}
