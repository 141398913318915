import { batchInsertEdge } from '@root/services/sdeInventories/edge.service.js';
import { Button, Modal, Divider } from 'antd';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import UploadButton from '../UploadButton.jsx';
import EdgeDevicesTable from './EdgeDevicesTable.jsx';

const edgeDeviceKeysToCheck = ['edge_id', 'status', 'thinggroup_name', 'baseimage_version', 'datetime_end'];

export default function EdgeDevices() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newEdges, setNewEdges] = useState(null);
  const [invalidDatas, setInvalidDatas] = useState([]);
  const [errorText, setErrorText] = useState(null);

  const queryClient = useQueryClient();
  const mutation = useMutation(batchInsertEdge, {
    onSuccess: () => {
      setIsModalOpen(false);
      const successModal = Modal.success({
        title: 'Import Edge device Successfully.',
      });
      queryClient.invalidateQueries(['get_edges']);
      queryClient.invalidateQueries(['get_sde_edge_application']);

      setTimeout(() => {
        successModal.destroy();
      }, 3000);
    },
  });

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    mutation.mutate(newEdges);
    setNewEdges(null);
  };
  const handleCancel = () => {
    setInvalidDatas([]);
    setNewEdges(null);
    setErrorText(null);
    setIsModalOpen(false);
  };
  const handleFileData = (fileData) => {
    // let fomattedData = { edges: [] };
    // fileData.forEach((element) => {
    //   fomattedData.edges.push({
    //     edgeBarcode: element.edge_id,
    //     deploymentStatus: element.status,
    //     thingGroup: element.thinggroup_name,
    //     baseImage: element.baseimage_version,
    //     manufactureDate: moment.tz(element.datetime_end, 'YYYY-MM-DD HH:mm:ss', 'Asia/Bangkok'),
    //   });
    // });
    // setNewEdges(fomattedData);

    if (fileData.every((device) => edgeDeviceKeysToCheck.every((key) => key in device))) {
      let fomattedData = { edges: [] };
      fileData.forEach((element) => {
        fomattedData.edges.push({
          edgeBarcode: element.edge_id,
          deploymentStatus: element.status,
          thingGroup: element.thinggroup_name,
          baseImage: element.baseimage_version,
          manufactureDate: moment.tz(element.datetime_end, 'YYYY-MM-DD HH:mm:ss', 'Asia/Bangkok'),
        });
      });
      setErrorText(null);
      setInvalidDatas([]);
      setNewEdges(fomattedData);
    } else {
      setInvalidDatas([]);
      setErrorText(
        `File data invalid, edge device data must include following columns: ${edgeDeviceKeysToCheck.join(', ')}`,
      );
      setNewEdges(null);
    }
  };
  return (
    <div>
      <div className="flex flex-row-reverse mb-4">
        <Button type="primary" onClick={showModal}>
          Import
        </Button>
      </div>
      <div>
        <EdgeDevicesTable />
      </div>
      <Modal
        title="Add Edge device"
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        maskClosable={false}
        okText="Import"
        okButtonProps={{ disabled: newEdges === null }}
      >
        <UploadButton handleFileData={handleFileData} />
        {errorText && (
          <div className="mt-7">
            <Divider></Divider> <div className=" text-red-600 ">{errorText}</div>
          </div>
        )}
        {invalidDatas.length > 0 && (
          <div>
            <div>
              <ul className="ml-5 list-decimal mt-2">
                {invalidDatas.map((data, index) => (
                  <li key={index}>{data.DeviceID}</li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
}
