import { getEdges } from '@root/services/sdeInventories/edge.service.js';
import { getColumnSearchProps } from '@root/utils/columnSearchProps.js';
import { Table } from 'antd';
import React from 'react';
import { useQuery } from 'react-query';
import DeploymentStatus from './DeploymentStatus.jsx';
import TableAction from './TableAction.jsx';
import { render } from '@testing-library/react';
import moment from 'moment';

export default function EdgeDevicesTable() {
  const { data: edgesData, isLoading } = useQuery('get_edges', getEdges, {});
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };
  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const columnSearchProps = getColumnSearchProps(handleSearch, handleReset);

  const columns = [
    {
      title: 'Edge barcode',
      width: 200,
      dataIndex: 'edgeBarcode',
      align: 'center',
      key: 'edgeBarcode',
      fixed: 'left',
      ...columnSearchProps('edgeBarcode'),
    },

    {
      title: 'Thing group',
      dataIndex: 'thingGroup',
      width: 200,
      align: 'center',
      ...columnSearchProps('thingGroup'),
    },
    {
      title: 'Deployment',
      dataIndex: 'deploymentStatus',
      width: 200,
      align: 'center',
      render: (value) => <DeploymentStatus status={value} />,
      ...columnSearchProps('deploymentStatus'),
    },
    {
      title: 'Base image',
      width: 300,
      dataIndex: 'baseImage',
      align: 'center',
      key: 'baseImage',
      ...columnSearchProps('baseImage'),
    },
    {
      title: 'Manufacture At',
      width: 300,
      dataIndex: 'manufactureDate',
      align: 'center',
      key: 'manufactureDate',
      render: (value) => {
        return value ? moment(value).format(' YYYY-MM-DD HH:mm:ss') : null;
      },
    },

    // {
    //   title: 'Components',
    //   width: 200,
    //   dataIndex: 'component',
    //   align: 'center',
    //   key: 'component',
    // },

    // {
    //   title: 'Updated by',
    //   dataIndex: 'updatedBy',
    //   align: 'center',
    //   width: 150,

    //   onFilter: (value, record) => record.address.indexOf(value) === 0,
    // },
    // {
    //   title: 'Updated at',
    //   dataIndex: 'updatedAt',
    //   width: 250,
    //   align: 'center',
    // },
    // {
    //   title: 'Created by',
    //   dataIndex: 'createdBy',
    //   width: 150,
    //   align: 'center',

    //   onFilter: (value, record) => record.address.indexOf(value) === 0,
    // },
    // {
    //   title: 'Created at',
    //   dataIndex: 'createdAt',
    //   width: 250,
    //   align: 'center',
    // },
    // {
    //   // title: 'Action',
    //   key: 'action',
    //   align: 'center',
    //   fixed: 'right',
    //   width: 135,
    //   render: (_, record) => <TableAction record={record} />,
    // },
  ];

  return (
    <div>
      <Table
        columns={columns}
        dataSource={edgesData?.data}
        scroll={{
          x: 1300,
        }}
        loading={isLoading}
      />
    </div>
  );
}
