export const controllerTypeOptions = [
  {
    value: 'sc_indoor',
    label: 'sc_indoor',
  },
  {
    value: 'sc_outdoor',
    label: 'sc_outdoor',
  },
  {
    value: 'sc_attic',
    label: 'sc_attic',
  },
  {
    value: 'ac_srtv',
    label: 'ac_srtv',
  },
  {
    value: 'ac_cv',
    label: 'ac_cv',
  },
  {
    value: 'battery_huawei',
    label: 'battery_huawei',
  },
  {
    value: 'ac_supply',
    label: 'ac_supply',
  },
  {
    value: 'inverter_uno',
    label: 'inverter_uno',
  },
  {
    value: 'inverter_trio',
    label: 'inverter_trio',
  },
  {
    value: 'inverter_pvi',
    label: 'inverter_pvi',
  },
  {
    value: 'inverter_pvs',
    label: 'inverter_pvs',
  },
  {
    value: 'inverter_sun2000_3ktl-l1',
    label: 'inverter_sun2000_3ktl-l1',
  },
  {
    value: 'inverter_sun2000_5ktl-l1',
    label: 'inverter_sun2000_5ktl-l1',
  },
  {
    value: 'inverter_sun2000_5ktl-m1',
    label: 'inverter_sun2000_5ktl-m1',
  },
  {
    value: 'inverter_sun2000_10ktl-m1',
    label: 'inverter_sun2000_10ktl-m1',
  },
  {
    value: 'inverter_sun2000_15ktl-m2',
    label: 'inverter_sun2000_15ktl-m2',
  },
  {
    value: 'inverter_sun2000_20ktl-m2',
    label: 'inverter_sun2000_20ktl-m2',
  },
  {
    value: 'inverter_sun2000_30ktl-m3',
    label: 'inverter_sun2000_30ktl-m3',
  },
  {
    value: 'meter_react',
    label: 'meter_react',
  },
  {
    value: 'meter_lovato',
    label: 'meter_lovato',
  },
  {
    value: 'meter_ddsu666',
    label: 'meter_ddsu666',
  },
  {
    value: 'meter_dtsu666',
    label: 'meter_dtsu666',
  },
  {
    value: 'meter_m4m_tcp',
    label: 'meter_m4m_tcp',
  },
  {
    value: 'dummy',
    label: 'dummy',
  },
  {
    value: 'tuya',
    label: 'tuya',
  },
];
