import React from 'react';
import { Form, Input, Button, Divider, Collapse } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import CustomDatePicker from '@root/components/atoms/CustomDatePicker.jsx';
import CustomSelect from '@root/components/atoms/CustomSelect.jsx';
import { useQuery } from 'react-query';
import { getSolutionOptions } from '@root/services/sdeInventories/sde.edge-application.service.js';
import ActuatorFormList from './ActuatorFormList.jsx';
import SensorFormList from './SensorFormList.jsx';
const { Panel } = Collapse;

export default function SolutionFormList({ form, solutionStates, record }) {
  const { data: solutionOptions } = useQuery('get_solution_options', getSolutionOptions, {});

  const onChange = (key) => {};
  const onApplicationNameChange = (value, applicationIndex) => {
    if (value === 'aaf' || value === 'aaq' || value === 'aafq') {
      const applications = form.getFieldValue(['applications']) || [];
      applications[applicationIndex] = {
        ...applications[applicationIndex],
        commercialName: 'Active Air Quality',
      };
      form.setFieldsValue({ applications });
    }

    checkIsTuyaApplication(applicationIndex);
  };

  const checkIsTuyaApplication = (applicationIndex) => {
    const targetApplicationName = form.getFieldValue(['applications', applicationIndex, 'applicationType']);
    if (targetApplicationName === 'tuya') {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <div className="col-span-4">
        <Divider orientation="left">Application devices</Divider>
        <Form.List name="applications" initialValue={[]}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, field }) => (
                <div key={key} className="m-2">
                  <Collapse onChange={onChange}>
                    <Panel
                      header={form.getFieldValue(['applications', name])?.applicationType}
                      key={key}
                      extra={<DeleteOutlined onClick={() => remove(name)} />}
                      forceRender={true}
                    >
                      <div className="grid grid-cols-4 gap-2">
                        <Form.Item
                          className="col-span-2"
                          {...field}
                          name={[name, 'applicationType']}
                          fieldKey={[fieldKey, 'applicationType']}
                          rules={[{ required: true, message: 'Please input the application name!' }]}
                        >
                          <CustomSelect
                            options={solutionOptions?.data}
                            placeholder="Select application"
                            // disable={record.applications[name] ? true : solutionStates[name]}
                            disable={
                              form.getFieldValue(['applications', name, 'applicationType'])
                                ? true
                                : solutionStates[name]
                            }
                            onChange={(value) => onApplicationNameChange(value, name)}
                          />
                        </Form.Item>
                        <Form.Item
                          className="col-span-2"
                          {...field}
                          name={[name, 'commercialName']}
                          fieldKey={[fieldKey, 'commercialName']}
                          rules={[{ required: true, message: 'Please input the commercial name!' }]}
                        >
                          <Input placeholder="Commercial name" />
                        </Form.Item>
                        <Form.Item
                          className="col-span-2"
                          {...field}
                          name={[name, 'installedDate']}
                          fieldKey={[fieldKey, 'installedDate']}
                          rules={[{ required: true, message: "Please input the application's installed date!" }]}
                        >
                          <CustomDatePicker placeholder="Select application's installed date" />
                        </Form.Item>
                      </div>

                      <ActuatorFormList
                        form={form}
                        applicationName={name}
                        fieldKey={fieldKey}
                        isAddButtonDisabled={
                          form.getFieldValue(['applications', name, 'applicationType'])
                            ? checkIsTuyaApplication(name)
                              ? true
                              : false
                            : !solutionStates[name]
                        }
                        record={record}
                      />
                      <SensorFormList
                        form={form}
                        applicationName={name}
                        fieldKey={fieldKey}
                        isAddButtonDisabled={
                          form.getFieldValue(['applications', name, 'applicationType'])
                            ? checkIsTuyaApplication(name)
                              ? true
                              : false
                            : !solutionStates[name]
                        }
                        record={record}
                      />
                    </Panel>
                  </Collapse>
                </div>
              ))}

              <Form.Item>
                <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
                  Add Solution
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </div>
    </>
  );
}
