import { SearchOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import React, { useRef } from 'react';

/**
 * Utility to generate Ant Design column search props.
 * @param {Function} handleSearch - Function to handle search logic.
 * @param {Function} handleReset - Function to reset search.
 * @returns {Function} - A function that generates column search props for a given dataIndex.
 */
export const getColumnSearchProps = (handleSearch, handleReset) => {
  const searchInput = useRef(null);

  return (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          value={`${selectedKeys[0] || ''}`}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <div className="grid grid-rows-2 gap-1">
          <div className="grid grid-cols-1">
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              block
            >
              Search
            </Button>
          </div>
          <div className="grid grid-cols-2 gap-1">
            <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" block>
              Reset
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                confirm({ closeDropdown: true });
              }}
              block
            >
              close
            </Button>
          </div>
        </div>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) => {
      return record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });
};

// export default getColumnSearchProps;
