import { instance } from '@root/utils/axios';

export const getSDEEdgeApplication = async () => {
  const res = await instance.get('/sde-edge-applications');
  return res;
};

export const editSDEEdgeApplication = async ({ recordId, body }) => {
  const res = await instance.put(`/sde-edge-applications/${recordId}`, body);
  return res;
};

export const getEdgeOptions = async () => {
  const res = await instance.get('/sde-edge-applications/edgeOptions');
  return res;
};

export const getDeveloperOptions = async () => {
  const res = await instance.get('/sde-edge-applications/developerOptions');
  return res;
};

export const getProjectOptions = async ({ queryKey }) => {
  const [_key, { selectedDeveloper }] = queryKey;
  const res = await instance.get(`/sde-edge-applications/projectOptions?developer=${selectedDeveloper}`);
  return res;
};

export const getSolutionOptions = async () => {
  const res = await instance.get('/sde-edge-applications/solutionOptions');
  return res;
};

export const createEdgeApplication = async (newEdgeApplication) => {
  const res = await instance.post('/sde-edge-applications', newEdgeApplication);
  return res;
};

export const getActuatorDeviceBarcodeOptions = async () => {
  const res = await instance.get('/sde-edge-applications/actuatorDeviceBarcodeOptions');
  return res;
};

export const getSensorDeviceBarcodeOptions = async () => {
  const res = await instance.get('/sde-edge-applications/sensorDeviceBarcodeOptions');
  return res;
};

export const getActuatorTypeOptions = async (solution) => {
  const res = await instance.get(`/sde-edge-applications/actuatorTypeOptions?solution=${solution}`);
  return res;
};

export const getSensorTypeOptions = async (solution) => {
  const res = await instance.get(`/sde-edge-applications/sensorTypeOptions?solution=${solution}`);
  return res;
};

export const syncToEP = async ({ id }) => {
  const res = await instance.put(`/sde-edge-applications/syncToEP/${id}`);
  return res;
};
